import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, resolveDirective as _resolveDirective, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "text-h6" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_q_card_section = _resolveComponent("q-card-section")!
  const _component_q_separator = _resolveComponent("q-separator")!
  const _component_q_select = _resolveComponent("q-select")!
  const _component_q_icon = _resolveComponent("q-icon")!
  const _component_q_file = _resolveComponent("q-file")!
  const _component_q_editor = _resolveComponent("q-editor")!
  const _component_q_input = _resolveComponent("q-input")!
  const _component_q_btn = _resolveComponent("q-btn")!
  const _component_q_card_actions = _resolveComponent("q-card-actions")!
  const _component_q_form = _resolveComponent("q-form")!
  const _component_FormLoading = _resolveComponent("FormLoading")!
  const _component_q_card = _resolveComponent("q-card")!
  const _component_q_dialog = _resolveComponent("q-dialog")!
  const _directive_close_popup = _resolveDirective("close-popup")!

  return (_openBlock(), _createBlock(_component_q_dialog, {
    modelValue: _ctx.show,
    "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.show) = $event))
  }, {
    default: _withCtx(() => [
      _createVNode(_component_q_card, { style: {"width":"90vw","max-width":"900px"} }, {
        default: _withCtx(() => [
          _createVNode(_component_q_card_section, null, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.row.id ? "Editar Postagem" : "Nova Postagem"), 1)
            ]),
            _: 1
          }),
          _createVNode(_component_q_separator),
          _createVNode(_component_q_form, { onSubmit: _ctx.submit }, {
            default: _withCtx(() => [
              _createVNode(_component_q_card_section, null, {
                default: _withCtx(() => [
                  _createVNode(_component_q_select, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    label: "Tipo de Postagem",
                    class: "col-12",
                    modelValue: _ctx.tipo,
                    "onUpdate:modelValue": [
                      _cache[0] || (_cache[0] = ($event: any) => ((_ctx.tipo) = $event)),
                      _cache[1] || (_cache[1] = ($event: any) => (_ctx.handleTipo()))
                    ],
                    options: ['Geral', 'Turma', 'Curso'],
                    disable: _ctx.row.id != null
                  }, null, 8, ["modelValue", "disable"]),
                  (_ctx.tipo == 'Turma')
                    ? (_openBlock(), _createBlock(_component_q_select, {
                        key: 0,
                        dense: "",
                        outlined: "",
                        "lazy-rules": "",
                        "emit-value": "",
                        "map-options": "",
                        multiple: "",
                        "use-chips": "",
                        "stack-label": "",
                        label: "Turmas *",
                        class: "col-12",
                        "option-value": "id",
                        "option-label": "nome",
                        modelValue: _ctx.form.turmas,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.form.turmas) = $event)),
                        options: _ctx.turmas,
                        rules: [_ctx.requiredField],
                        disable: _ctx.row.id != null
                      }, null, 8, ["modelValue", "options", "rules", "disable"]))
                    : _createCommentVNode("", true),
                  (_ctx.tipo == 'Curso')
                    ? (_openBlock(), _createBlock(_component_q_select, {
                        key: 1,
                        dense: "",
                        outlined: "",
                        "lazy-rules": "",
                        "emit-value": "",
                        "map-options": "",
                        multiple: "",
                        "use-chips": "",
                        "stack-label": "",
                        label: "Cursos *",
                        class: "col-12",
                        "option-value": "id",
                        "option-label": "descricao",
                        modelValue: _ctx.form.cursos,
                        "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.form.cursos) = $event)),
                        options: _ctx.cursos,
                        rules: [_ctx.requiredField],
                        disable: _ctx.row.id != null
                      }, null, 8, ["modelValue", "options", "rules", "disable"]))
                    : _createCommentVNode("", true),
                  _createVNode(_component_q_file, {
                    dense: "",
                    outlined: "",
                    class: "col-12 q-mb-sm",
                    label: "Anexar Arquivo",
                    "aria-label": "Anexar Arquivo",
                    modelValue: _ctx.form.file,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.form.file) = $event))
                  }, {
                    prepend: _withCtx(() => [
                      _createVNode(_component_q_icon, {
                        name: "attach_file",
                        onClick: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["stop"]))
                      })
                    ]),
                    _: 1
                  }, 8, ["modelValue"]),
                  _createVNode(_component_q_editor, {
                    modelValue: _ctx.form.texto,
                    "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.form.texto) = $event)),
                    class: "q-mt-md",
                    "min-height": "5rem",
                    toolbar: [
              [
                'bold',
                'italic',
                'strike',
                'underline',
                'subscript',
                'superscript',
              ],
              ['token', 'hr', 'link', 'custom_btn'],
              ['quote', 'unordered', 'ordered'],
              ['left', 'center', 'right', 'justify'],
            ]
                  }, null, 8, ["modelValue"]),
                  _createVNode(_component_q_select, {
                    dense: "",
                    outlined: "",
                    "lazy-rules": "",
                    "emit-value": "",
                    "map-options": "",
                    "stack-label": "",
                    label: "Divisão *",
                    class: "col-12",
                    "option-value": "id",
                    "option-label": "descricao",
                    modelValue: _ctx.form.divisao_id,
                    "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.form.divisao_id) = $event)),
                    options: _ctx.divisoes,
                    rules: [_ctx.requiredField]
                  }, null, 8, ["modelValue", "options", "rules"]),
                  _createElementVNode("div", _hoisted_2, [
                    _createVNode(_component_q_input, {
                      dense: "",
                      outlined: "",
                      "lazy-rules": "",
                      type: "date",
                      class: "col-6 q-mb-sm",
                      label: "Data Exibição",
                      modelValue: _ctx.form.data_exibicao,
                      "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.form.data_exibicao) = $event))
                    }, null, 8, ["modelValue"]),
                    _createVNode(_component_q_input, {
                      dense: "",
                      outlined: "",
                      "lazy-rules": "",
                      type: "date",
                      class: "col-6 q-mb-sm",
                      label: "Data Fim Fixado",
                      modelValue: _ctx.form.data_fixado,
                      "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.form.data_fixado) = $event))
                    }, null, 8, ["modelValue"])
                  ])
                ]),
                _: 1
              }),
              _createVNode(_component_q_separator),
              _createVNode(_component_q_card_actions, { align: "right" }, {
                default: _withCtx(() => [
                  _createVNode(_component_q_btn, {
                    type: "submit",
                    color: "primary",
                    class: "q-ma-sm",
                    icon: "save",
                    label: "Salvar"
                  }),
                  _withDirectives(_createVNode(_component_q_btn, {
                    class: "q-ma-sm",
                    icon: "close",
                    label: "Cancelar",
                    color: "red"
                  }, null, 512), [
                    [_directive_close_popup]
                  ])
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["onSubmit"]),
          _createVNode(_component_FormLoading, { loading: _ctx.loading }, null, 8, ["loading"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["modelValue"]))
}